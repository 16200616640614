import styled from 'styled-components';

export const StyledInput = styled.footer`
    width: 100%;
    height: auto;
    

`

export const StyledForm = styled.form`
    display: flex;
    flex-direction: row;
    height: 10vh;
   
    // border-top: 1px solid #D3D3D3;
    border-radius: 0 0 4px 4px;
    
    .input-wrapper {
        
        width: 100%;
        height: 100%;
        
  
        
    }
    .input {
        width: 100%;
        height: 10vh;
        font-family: 'Arial';
        border:none;
        font-size: 1.4rem;
        overflow: hidden;
        flex-grow: 1;
        background: #212122;
        color: #ffffff;
        
       
    }

    textarea::placeholder {
        padding-top: 5px;
        padding-left: 5px;
    }
      


    .send-button {
        color: #fff;
        text-decoration: none;
        background: #6f9da5;
        padding: 20px;
        font-size: 1rem;
        display: inline-block;
        border: none;
        width: 20%;
        font-size: 1.3rem;
        height: 100%;
        flex-grow: 1;
        line-height: 1px;
       


    }







  
`