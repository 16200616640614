import styled from 'styled-components'

export const StyledAppContainer = styled.div`
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #212122;
`

export const StyledVideoContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: #212122;
    border-radius: 8px;
    height: 100vh;
    width: 100%;
`

export const StyledViewerRow = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
`

export const StyledAppRow = styled.div`
    display: flex;
    width: 100%;
    height: 70vh;
    justify-content: flex-start;
    flex-wrap: wrap;
    


`

export const StyledUsersToCall = styled.div`
    display: flex;
    width: 100%;
    height: 10vh;
    justify-content: center;
    align-items: flex-end;
    flex-wrap: wrap;
    margin-bottom: 20px;
    margin-right: 20px;
`

export const StyledIncomingCallRow = styled.div`
    display: flex;
    width: 100%;
    height: 10vh;
    justify-content: center;
    align-items: space-between;

    .incoming-call {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        h1 {
            color: white;
        }
        
    }

    .accept-decline-buttons {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .accept-call {
        color: green;
        font-size: 24pt;
        cursor: pointer;
        
    }

    .decline-call {
        color: red;
        font-size: 24pt;
        cursor: pointer;
    }
`

export const StyledVideo = styled.video`
    width: 60%;
    height: auto;
    margin-bottom: 10px;
    margin-top: 10px;
`

export const StyledVideoUser = styled.video`
    width: 10%;
    height: auto;
    position: absolute;
    top: 10px;
    left: 10px;
`

export const StyledVideoButton = styled.button`
    background: #6f9da5;
    height: 3.4rem;
    width: 5rem;
    color: white;
    font-size: 12pt;
    border: none;
    cursor: pointer;
    border-radius: 4px 4px 4px 4px;
    margin-bottom: 2px;
    margin-left: 2px;
`