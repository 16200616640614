import React from 'react';
import Loader from 'react-loader-spinner'
import ScrollToBottom from 'react-scroll-to-bottom';

import { StyledMessages } from './styles/StyledMessages';

import Message from './Message';

const Messages = ({ messages, name }) => {
        if(messages.length < 1) {
            return <StyledMessages>
                        <div className="loader">
                            <Loader 
                                type="ThreeDots"
                                color="#6f9da5"	
                                height={50}
                                width={50}
                            />
                        </div>
                    </StyledMessages>
        }

        return (
            <ScrollToBottom className="messages">
                <StyledMessages>
                    {messages.map((message, i) => <div key={i}><Message message={message} name={name}/></div>)}
                </StyledMessages>
            </ScrollToBottom>
        )
    
}

export default Messages;