import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { StyledVideoInfoBar, LeftContainer, RightContainer } from './styles/StyledInfoBar'

import closeIcon from '../icons/closeIcon.png';
import onlineIcon from '../icons/onlineIcon.png';
import { BsChatDots } from 'react-icons/bs'
const VideoInfoBar = ({ name, room }) => {
    return (
        <StyledVideoInfoBar>
            <LeftContainer>
                <img className="onlineIcon" src={onlineIcon} alt="" />
                <Link
                    to={{
                        pathname: '/chat',
                        search: `?name=${name}&room=${room}`,
                    }}
                    >
                        <BsChatDots className="chat-icon" />
                </Link>
                <h3>{room}</h3>
            </LeftContainer>    
            <RightContainer>
                <a href="/"><img src={closeIcon} alt="" /></a>
            </RightContainer>
        </StyledVideoInfoBar>
    )
}

export default VideoInfoBar