import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { StyledJoinWrapper, StyledJoin } from './styles/StyledJoin'

const  Join = () => {
	const [name, setName] = useState('');
	const [room, setRoom] = useState('');

	return (
		<StyledJoinWrapper> 
			<div className="intro">
				<h1>Welcome to <span className="title">VyoChat</span></h1>
				<h4>Join a room.</h4>
				<h4>Or create your own.</h4>
				<h4>Invite friends.</h4>
				<h4>Chat and video.</h4>
			</div>

			<StyledJoin>
				
				
				<div><input placeholder="name" className="joinInput" type="text" onChange={(event) => setName(event.target.value)} /></div>
				<div><input placeholder="room" className="joinInput mt-20" type="text" onChange={(event) => setRoom(event.target.value) } /></div>
				<Link onClick={event => (!name || !room) ? event.preventDefault() : null} to={`/chat?name=${name}&room=${room}`}>
					<button className="button mt-20" type="submit">sign in</button>
				</Link>
			</StyledJoin>
		</StyledJoinWrapper>
	)
}
 
export default Join;