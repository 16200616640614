import React from 'react';
import ReactEmoji from 'react-emoji';

import { StyledMessage } from './styles/StyledMessage';



const Message = ({ message: { user, text }, name }) => {
    let isSentByCurrentUser = false;

    const trimmedName = name.trim().toLowerCase();

    if(user === trimmedName) {
        isSentByCurrentUser = true;
    }
    
    return (
        isSentByCurrentUser 
        ? (
            <StyledMessage className="justifyEnd">
                <p className="sentText pr-10">{trimmedName}</p>
                <div className="messageBox backgroundBlue">
                    <p className="messageText colorWhite">{ReactEmoji.emojify(text)}</p>
                </div>
            </StyledMessage>
        ) : (
            <StyledMessage className="justifyStart">
                <div className="messageBox backgroundLight">
                    <p className="messageText colorDark">{ReactEmoji.emojify(text)}</p>
                </div>
                <p className="sentText pl-10">{user}</p>
            </StyledMessage>

        )
  
    );

}

export default Message;