import React, {useState, useRef, useEffect} from 'react'
import VideoInfoBar from './VideoInfobar'
import queryString from 'query-string';
import { FiPhoneCall, FiPhoneOff } from 'react-icons/fi'
import { StyledUsersToCall, StyledIncomingCallRow, StyledVideoButton, StyledAppContainer, StyledViewerRow, StyledVideoContainer, StyledAppRow, StyledVideo, StyledVideoUser } from './styles/StyledVideo'
import io from 'socket.io-client'
import Peer from 'simple-peer'

const Video = (props) => {
    const [yourName, setYourName] = useState('')
    const [yourID, setYourID] = useState('')
    const [videoRoom, setVideoRoom] = useState('')
    const [allUsers, setAllUsers] = useState([])
    const [stream, setStream] = useState()
    const [receivingCall, setReceivingCall] = useState(false)
    const [caller, setCaller] = useState('')
    const [callerName, setCallerName] = useState('')
    const [callerSignal, setCallerSignal] = useState()
    const [callAccepted, setCallAccepted] = useState(false)
    const [callDeclined, setCallDeclined] = useState(false)
    const [declinedMessage] = useState('Call Declined.')

    
    const ENDPOINT = 'https://vyochat.herokuapp.com/';

    const userVideo = useRef()
    const partnerVideo = useRef()
    const socket = useRef()


    useEffect(() => {
        let { name, room } = queryString.parse(props.location.search);

        name = name.trim().toLowerCase();
		room = room.trim().toLowerCase();

        setYourName(name)
        setVideoRoom(room)
        


        socket.current = io.connect(ENDPOINT)


        
    
        socket.current.emit('joinVideo', { name, room }, () => {
        
        })
    }, [ENDPOINT, props.location.search])



    useEffect(() => {
        navigator.mediaDevices.getUserMedia({ video: true, audio: true }).then(stream => {
            setStream(stream)
            if(userVideo.current) {
                userVideo.current.srcObject = stream
            }
        })

        socket.current.on("usersToVideo", ({ users }) => {
            console.log('hi')
            console.log(users)
            setAllUsers(users)
        })
      

        socket.current.on("yourID", (id, name, room) => {
            setYourID(id)
            
        })

        socket.current.on("hey", (data) => {
            console.log(data)
            setReceivingCall(true)
            setCaller(data.from)
            setCallerName(data.name)
            setCallerSignal(data.signal)
        })
    }, [])

   

    function callPeer(id) {
        const peer = new Peer({
            initiator: true,
            trickle: false,
            config: {

                iceServers: [
                  {
                    urls: "stun:numb.viagenie.ca",
                    username: "ashleymvanduzer@gmail.com",
                    credential: "NeuroNeuro2020"
                  },
                  {
                    urls: "turn:numb.viagenie.ca",
                    username: "ashleymvanduzer@gmail.com",
                    credential: "NeuroNeuro2020"
                  }
                ]
              },
            stream: stream,
        })

        peer.on("signal", data => {
            socket.current.emit("callUser", {
                userToCall: id,
                signalData: data,
                from: yourID,
                name: yourName 
            })
        })
    
        peer.on("stream", stream => {
            if (partnerVideo.current) {
                partnerVideo.current.srcObject = stream
            }
        })

    
        socket.current.on("callAccepted", signal => {
            setCallAccepted(true)
            peer.signal(signal)
        })

        socket.current.on("callDeclined", message => {
            setCallAccepted(false)
            setCallDeclined(true)
        })

    }

    function declineCall() {
        setCallDeclined(true)

  
        socket.current.emit("declineCall", {
            to: caller
        })
      

    }


    function acceptCall() {
        setCallAccepted(true)
        const peer = new Peer({
            initiator: false,
            trickle: false,
            stream: stream,
        })

        peer.on("signal", data => {
            socket.current.emit("acceptCall", {
                signal: data,
                to: caller
            })
        })

        peer.on("stream", stream => {
            partnerVideo.current.srcObject = stream
        })

        peer.signal(callerSignal)
    }

    let UserVideo
    if(stream) {
        UserVideo = (
            <StyledVideoUser playsInline muted ref={userVideo} autoPlay />
        )
    }

    let PartnerVideo
    if(callAccepted) {
        PartnerVideo = (
            <StyledVideo playsInline ref={partnerVideo} autoPlay />
        )
    }

    let incomingCall
    if (receivingCall) {
        incomingCall = (
            <div className="incoming-call">
                <h1>{callerName} is calling you.</h1>
                <div className="accept-decline-buttons">
                    <FiPhoneCall className="accept-call" onClick={acceptCall}></FiPhoneCall>
                    <FiPhoneOff className="decline-call" onClick={declineCall}></FiPhoneOff>
                </div>
            </div>
        )
    }

    if(receivingCall && callAccepted) {
        incomingCall = (
            <div>

            </div>
        )
    }

    if(callDeclined) {
        incomingCall = (
            <div>
                {declinedMessage}
            </div>
        )
    }

    return (
        <StyledAppContainer>
            <StyledVideoContainer>
            <VideoInfoBar name={yourName} room={videoRoom} />
                <StyledViewerRow>
                    {UserVideo}
                    {PartnerVideo}
                </StyledViewerRow>
                <StyledIncomingCallRow>
                    {incomingCall}
                </StyledIncomingCallRow>
                <StyledUsersToCall>
                    {Object.keys(allUsers).map(key => {
                    if (allUsers[key].name === yourName) {
                        return null
                    }
                    return (
                        <StyledVideoButton key={key} onClick={() => callPeer(allUsers[key].id)}>{allUsers[key].name}</StyledVideoButton>
                    )
                    })}
                </StyledUsersToCall>
            </StyledVideoContainer>
        </StyledAppContainer>
    )
}

export default Video