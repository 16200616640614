import React from 'react';
import { GrSend } from 'react-icons/gr'
import { StyledInput, StyledForm } from './styles/StyledInput';

const Input = ({ message, setMessage, sendMessage }) => {
    return (
   <StyledInput>
       <StyledForm>
           <div className="input-wrapper">
                <textarea 
                        className="input"
                        type="text" 
                        placeholder="message..."
                        value={message}
                        onChange={(event) => setMessage(event.target.value)}
                        onKeyPress={event => event.key === 'Enter' ? sendMessage(event) : null}
                />
            </div>
            <button className="send-button" onClick={(event) => sendMessage(event)}>send</button>

       </StyledForm>
   </StyledInput>
   )
}

export default Input;