import styled from 'styled-components';

export const StyledInfoBar = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #6f9da5;
    border-radius: 4px 4px 0 0;
    height: 10vh;
    width: 100%;

`

export const StyledVideoInfoBar = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #6f9da5;
    border-radius: 4px 4px 0 0;
    height: 10vh;
    width: 100%;
`

export const LeftContainer = styled.div`
    flex: 0.5;
    display: flex;
    align-items: center;
    margin-left: 5%;
    color: white;

    h3 {
        margin-left: 10px;
        padding-bottom: 4px;
    }

    .video-icon {
        cursor: pointer;
        color: white;
        font-size: 24px;
    }

    .chat-icon {
        cursor: pointer;
        color: white;
        font-size: 24px;
    }

`

export const RightContainer = styled.div`
    display: flex;
    flex: 0.5;
    justify-content: flex-end;
    margin-right: 5%;
    font-size: 24px;

   
 
`

