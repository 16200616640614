import React from 'react';
import { Link } from 'react-router-dom'

import closeIcon from '../icons/closeIcon.png';
import onlineIcon from '../icons/onlineIcon.png';
import { BsCameraVideo } from 'react-icons/bs'

import { StyledInfoBar, LeftContainer, RightContainer } from './styles/StyledInfoBar';

const InfoBar = ({ name, room, users }) => {

    return (
    <StyledInfoBar>
        <LeftContainer>
            <img className="onlineIcon" src={onlineIcon} alt="" />

            <Link onClick={event => (!name || !room) ? event.preventDefault() : null}
                to={{
                pathname: '/video',
                search: `?name=${name}&room=${room}`,
                state: {users}
            }}>
                    <BsCameraVideo className="video-icon" />
            </Link>
            <h3>{room}</h3>
        </LeftContainer>
        <RightContainer>
            <a href="/"><img src={closeIcon} alt="" /></a>
        </RightContainer>
    </StyledInfoBar>
    )
}

export default InfoBar;