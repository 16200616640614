import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import io from 'socket.io-client';
import InfoBar from './InfoBar';
import Input from './Input';
import Messages from './Messages';

import { StyledChat, StyledChatWrapper } from './styles/StyledChat';

let socket;

const Chat = ({ location }) => {
	const [name, setName] = useState('');
	const [room, setRoom] = useState('');
	const [users, setUsers] = useState([])
	const [message, setMessage] = useState('');
	const [messages, setMessages] = useState([]);


	const ENDPOINT = 'https://vyochat.herokuapp.com/';

	
	useEffect(() => {
		
		let sessionStorageName;
		const { name, room } = queryString.parse(location.search);
		sessionStorage.setItem('name', name)
		sessionStorageName = sessionStorage.name
		console.log(sessionStorageName)
		socket = io(ENDPOINT, {
			withCredentials: true,
			transportOptions: {
				polling: {
					extraHeaders: {
						'Content-Type': 'multipart/form-data'
					}
				}
			}
		});

		setName(name);
		setRoom(room);


		
		socket.emit('join', { name, room, sessionStorageName }, (error) => {
			if(error) {
				alert(error)
			}
		});

		
	}, [ENDPOINT, location.search]);


	useEffect(() => {
		socket.on('message', (message) => {
			setMessages(messages => [...messages, message])

		})

		socket.on('roomData', ({ users }) => {
			setUsers(users)
		})
	}, [])

	const sendMessage = (event) => {
		event.preventDefault();

		if(message) {
			socket.emit('sendMessage', message, () => setMessage(''))
		}
	}

	console.log(message, messages);

	return (
		<StyledChatWrapper>
			<StyledChat>
				<InfoBar users={users} name={name} room={room} />
				<Messages messages={messages} name={name} />
				<Input message={message} setMessage={setMessage} sendMessage={sendMessage} />
			</StyledChat>
		</StyledChatWrapper>
	)
}

export default Chat;