import styled from 'styled-components';

export const StyledJoinWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    
    background-color: #212122;
    color: white;
    .intro {
        width: 40%;
    }

    .title {
        color: #6f9da5;
        font-weight: bolder;
       
    }
    
`

export const StyledJoin = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #212122;
    border-radius: 8px;
    color: white;
   
`

