import styled from 'styled-components';

export const StyledChatWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    min-width: 100vw;
    background-color: #1A1A1D;
    overflow: hidden;

`

export const StyledChat = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: #212122;
    border-radius: 8px;
    height: 100vh;
    width: 100vw;

`
